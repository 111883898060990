<template>
  <b-form-group>
    <label for="status" class="d-flex">
      Situação da cirurgia
      <div class="ml-auto">
        <b-button variant="link" @click="$bvModal.show('logs-modal')">
          Ver log
        </b-button>
      </div>
    </label>
    <multiselect
      id="status"
      placeholder="Selecionar..."
      :value="selectedStatus"
      @select="selectStatus"
      class="with-border"
      :options="options"
      :showLabels="false"
      :internalSearch="false"
      :searchable="false"
      :disabled="isLoadding"
    >
      <template slot="caret">
        <div class="chevron">
          <ChevronDown />
        </div>
      </template>

      <template slot="option" slot-scope="props">
        <span class="option">
          <div :class="`situation ${props.option.value}`" />
          {{ props.option.label }}
        </span>
      </template>

      <template slot="singleLabel" slot-scope="option">
        <span class="option">
          <div :class="`situation ${option.option.value}`" />
          {{ option.option.label }}
        </span>
      </template>

      <template slot="noOptions"> Nenhuma opção </template>
      <template slot="noResult"> Nenhum resultado </template>
    </multiselect>
    <div v-if="statusTime" class="last-update">
      Atualizado em {{ parseTimestamp(statusTime) }}
    </div>
  </b-form-group>
</template>

<script>
import { parseTimestamp } from '@/utils/dateHelper'
import { getSurgeryLabelStatus } from '@/utils/surgeryCenterHelper'

export default {
  name: 'StatusSelect',
  props: {
    statusTime: String,
    status: String,
    isLoadding: Boolean
  },
  computed: {
    selectedStatus: function () 
      { return this.options.find(option => option.label === this.status) },
    options: function () { return getSurgeryLabelStatus() }
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  methods: {
    parseTimestamp,
    selectStatus(status) {
      this.$emit('change-status', status.label)
    }
  }
}
</script>

<style lang="scss">
.last-update {
  font-size: 0.8rem;
  color: var(--type-placeholder);
}

.form-group {
  margin-bottom: 0 !important;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.situation {
  width: 18px;
  height: 18px;
  border: 2px solid var(--neutral-500);
  border-radius: 50%;

  &.STATUS_RESERVED {
    background-color: #486ef6;
    border-color: #486ef6;
  }

  &.STATUS_CONFIRMED {
    background-color: #00977c;
    border-color: #00977c;
  }

  &.STATUS_FIRST_CALL {
    background-color: #00c2ff;
    border-color: #00c2ff;
  }

  &.STATUS_ADMISSION_COMPLETED {
    background-color: #04e36b;
    border-color: #04e36b;
  }

  &.STATUS_READY_FOR_SURGERY {
    background-color: #c556d7;
    border-color: #c556d7;
  }

  &.STATUS_IN_SURGERY {
    background-color: #ffab28;
    border-color: #ffab28;
  }

  &.STATUS_RECOVERY_ROOM {
    background-color: #c27245;
    border-color: #c27245;
  }

  &.STATUS_FINISHED {
    background-color: #f8fdff;
    border: 3px solid #304388;
  }

  &.STATUS_MISSED {
    background-color: #ff6969;
    border: 3px solid #ff6969;
  }

  &.STATUS_CANCELED {
    background-color: var(--neutral-500);
    border: var(--neutral-500);
  }
}
</style>
